<template>
    <div class="w-100">
        <div class="p-3 p-md-5 bg-blue ">
            <div class="container">
                <div class="row text-center">
                    <div class="col-lg-3">
                        <a class="link-secondary text-center" href="#"> <img alt="..."
                                class="img-fluid mb-4 ls-is-cached lazyloaded"
                                width="100%"
                                style="max-width: 100px;"
                                :src="require('@/assets/images/'+getLogo1)" >
                        </a>
                    </div>
                    <div class="col-lg-9 d-flex align-items-center justify-content-center">
                        <p class="text-white">ยินดีต้อนรับเข้าสู่ {{ AGENT_S_BASE }} เว็บเดิมพันสล็อตและคาสิโนออนไลน์ และกีฬาออนไลน์
                            สมัครสมาชิกฟรี เติมเงิน-ถอน ไม่มีขั้นต่ำ รวดเร็วด้วยระบบออโต้ Super speed เล่นได้ทุกค่ายทุกเกม
                            จบครบในที่เดียว ไม่โยกเงินมีเจ้าหน้าที่คอยให้บริการตลอด 24 ชม. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-black p-2">
<p class="text-center mb-0 text-white">© 2023 {{ AGENT_S_BASE }} v1.4.1 All rights reserved. </p>
</div>
</div></template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getLogo1'])
  },
  data () {
    return {
      AGENT_S_BASE: process.env.VUE_APP_AGENT_BASE,
      AGENT_EXT_NAME: process.env.VUE_APP_EXT_NAME
    }
  }
}
</script>
